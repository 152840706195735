<template>
  <div class="order__food__wrapper">
    <div
      class="last__orders__wrapper"
      v-if="last_orders.length > 0 && order_food.length === 0"
    >
      <h3>Последние заказы</h3>
      <div class="last__orders__items">
        <div
          class="last__order__item"
          v-for="(item, index) in last_orders"
          :key="index"
          @click="func_show_order(item)"
        >
          <div class="date">
            {{ new Date(item.dateOrder).toLocaleDateString() }}
          </div>
          <div class="school">{{ item.school }}</div>
          <div class="class">{{ item.class }}</div>
          <div class="count">Кол-во учащихся: {{ item.count_reb || 0 }}</div>
        </div>
      </div>
    </div>
    <h3>Заказать питание</h3>
    <form @submit.prevent="func_get_data" v-if="order_food.length === 0">
      <div
        class="form__group"
        v-if="USER_STATE && Number(USER_STATE.type) === 5 && false"
      >
        <label>Логин учителя</label>
        <input type="text" v-model="login_teacher" />
      </div>
      <div class="form__group">
        <label>Школа</label>
        <v-select
          label="school"
          :options="school_list"
          v-model="school_value"
          @option:selecting="func_get_class_list"
        />
      </div>
      <div class="form__group">
        <label>Класс</label>
        <v-select
          label="class"
          :options="class_list"
          v-model="class_value"
          @option:selecting="order_food = []"
        />
      </div>
      <div class="form__group">
        <label>Дата</label>
        <input type="date" required v-model="date" />
      </div>
      <button class="send">Заказать питание</button>
    </form>
    <div class="table__result" v-if="order_food.length > 0">
      <div class="info__color">
        <ul>
          <li><span style="background: red"></span> - Баланс &lt; 60</li>
          <li>
            <span style="background: orange"></span> - Баланс > 60 и &lt; 200
          </li>
          <li><span style="background: green"></span> - Баланс > 200</li>
        </ul>
      </div>

      <div class="table__header">
        <div class="left">
          <h4>{{ new_order ? 'Создание заказа' : 'Редактирование заказа' }}</h4>
          <p>Школа: {{ this.school_value.school }}</p>
          <p>Класс: {{ this.class_value.class }}</p>
          <p>Дата: {{ new Date(this.date).toLocaleDateString() }}</p>
        </div>
        <div class="right">
          <button @click="func_clear_order()">Очистить заказ</button>
        </div>
      </div>
      <button
        v-if="!new_order"
        class="btn__refresh_student"
        @click="func_refresh_student"
      >
        Обновить список учеников
      </button>
      <table class="order__food">
        <thead>
          <tr class="first__row">
            <th rowspan="3">№ПП</th>
            <th rowspan="3">ФИО учащегося/преподавателя</th>
            <th rowspan="3">Льгота</th>
            <th
              colspan="2"
              class="color1"
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              Бесплатное питание (1-4 классы)
            </th>
            <th colspan="3" class="color2">Платное питание</th>
            <th colspan="3">Льготное питание</th>
            <th colspan="2" class="color3">Льготное питание + доплата</th>
            <th
              colspan="3"
              class="color4"
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              ГПД
            </th>
          </tr>
          <tr>
            <th
              class="color1"
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              Завтрак
            </th>
            <th
              class="color1"
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              Обед
            </th>
            <th class="color2">Завтрак</th>
            <th class="color2">Обед</th>
            <th class="color2">Полдник</th>
            <th class="color5">Завтрак</th>
            <th class="color5">Обед</th>
            <th class="color5">Полдник</th>
            <th class="color3">Завтрак</th>
            <th class="color3">Обед</th>
            <th
              class="color4"
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              Платное
            </th>
            <th
              class="color4"
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              Льготника
            </th>
            <th
              class="color4"
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              Льготника с доплатой
            </th>
          </tr>
          <tr class="last__row">
            <th
              class="color1"
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              <input
                type="checkbox"
                :disabled="!dostup"
                v-model="select_all_bp"
              />
            </th>
            <th
              class="color1"
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              <input
                type="checkbox"
                :disabled="!dostup"
                v-model="select_all_bpO"
              />
            </th>
            <th class="color2">
              <input
                type="checkbox"
                v-model="select_all_pp"
                :disabled="!dostup"
              />
            </th>
            <th class="color2">
              <input
                type="checkbox"
                v-model="select_all_ppO"
                :disabled="!dostup"
              />
            </th>
            <th class="color2">
              <input
                type="checkbox"
                v-model="select_all_ppP"
                :disabled="!dostup"
              />
            </th>
            <th class="color5">
              <input
                type="checkbox"
                v-model="select_all_lgZ1_food"
                :disabled="!dostup"
              />
            </th>
            <th class="color5">
              <input
                type="checkbox"
                v-model="select_all_lgO1_food"
                :disabled="!dostup"
              />
            </th>
            <th class="color5">
              <input
                type="checkbox"
                v-model="select_all_lgP1_food"
                :disabled="!dostup"
              />
            </th>
            <th class="color3">
              <input
                type="checkbox"
                v-model="select_all_lg_zav"
                :disabled="!dostup"
              />
            </th>
            <th class="color3">
              <input
                type="checkbox"
                v-model="select_all_lg_ob"
                :disabled="!dostup"
              />
            </th>
            <th
              class="color4"
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              <input type="checkbox" v-model="select_all_lg_gpd" />
            </th>
            <th
              class="color4"
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              <input type="checkbox" v-model="select_all_lg_gpdP" />
            </th>
            <th
              class="color4"
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              <input type="checkbox" v-model="select_all_lg_gpdPD" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in order_food" :key="item.id">
            <td :style="{ background: func_get_color(item) }">
              {{ index + 1 }}
            </td>
            <td>{{ item.fio }}</td>
            <td>{{ item.LGOTA === '1' ? item.type_lgota || 'Л' : '' }}</td>
            <td
              class="color1"
              v-if="Number(item.class.toString().replace(/[^0-9]/g, '')) <= 4"
            >
              <input
                type="checkbox"
                v-model="item.bp"
                :disabled="!dostup"
                @change="func_update_bp_food(item, 'bp')"
              />
            </td>
            <td
              class="color1"
              v-if="Number(item.class.toString().replace(/[^0-9]/g, '')) <= 4"
            >
              <input
                type="checkbox"
                v-model="item.bpO"
                :disabled="!dostup"
                @change="func_update_bp_food(item, 'bpO')"
              />
            </td>
            <td class="color2">
              <input
                type="checkbox"
                v-model="item.pp"
                :disabled="func_check_pp(item.pp, item)"
              />
            </td>
            <td class="color2">
              <input
                type="checkbox"
                v-model="item.ppO"
                :disabled="func_check_pp(item.ppO, item)"
              />
            </td>
            <td class="color2">
              <input
                type="checkbox"
                v-model="item.ppP"
                :disabled="func_check_pp(item.ppP, item)"
              />
            </td>
            <td style="text-align: center">
              <input
                type="checkbox"
                v-model="item.lgZ1"
                :disabled="func_check_lg(item.lgZ1, item, false)"
                @change="func_update_lg_food(item, 'lgZ1')"
              />
            </td>
            <td style="text-align: center">
              <input
                type="checkbox"
                v-model="item.lgO1"
                :disabled="func_check_lg(item.lgO1, item, false)"
                @change="func_update_lg_food(item, 'lgO1')"
              />
            </td>
            <td style="text-align: center">
              <input
                type="checkbox"
                v-model="item.lgP1"
                :disabled="item.type_lgota !== 'овз'"
                @change="func_update_lg_food(item, 'lgP1')"
              />
            </td>
            <td class="color3">
              <input
                type="checkbox"
                v-model="item.lgZ"
                :disabled="func_check_lg(item.lgZ, item, true)"
                @change="func_update_lg_food(item, 'lgZ')"
              />
            </td>
            <td class="color3">
              <input
                type="checkbox"
                v-model="item.lgO"
                :disabled="func_check_lg(item.lgO, item, true)"
                @change="func_update_lg_food(item, 'lgO')"
              />
            </td>
            <td
              class="color4"
              v-if="Number(item.class.toString().replace(/[^0-9]/g, '')) <= 4"
            >
              <input
                type="checkbox"
                v-model="item.gpd"
                :disabled="func_check_gpd(item.gpd, item)"
                @change="func_update_lg_food(item, 'gpd')"
              />
            </td>
            <td
              class="color4"
              v-if="Number(item.class.toString().replace(/[^0-9]/g, '')) <= 4"
            >
              <input
                type="checkbox"
                v-model="item.gpdP"
                :disabled="func_check_gpdP(item.gpdP, item, true)"
                @change="func_update_lg_food(item, 'gpdP')"
              />
            </td>
            <td
              class="color4"
              v-if="Number(item.class.toString().replace(/[^0-9]/g, '')) <= 4"
            >
              <input
                type="checkbox"
                v-model="item.gpdPD"
                :disabled="func_check_gpdPD(item.gpdPD, item, true)"
                @change="func_update_lg_food(item, 'gpdPD')"
              />
            </td>
          </tr>
          <tr>
            <th></th>
            <th>Итого:</th>
            <th></th>
            <th
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              {{ func_get_summ(1) }}
            </th>
            <th
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              {{ func_get_summ(2) }}
            </th>
            <th>{{ func_get_summ(3) }}</th>
            <th>{{ func_get_summ(4) }}</th>
            <th>{{ func_get_summ(5) }}</th>
            <th>{{ func_get_summ(6) }}</th>
            <th>{{ func_get_summ(7) }}</th>
            <th>{{ func_get_summ(8) }}</th>
            <th>{{ func_get_summ(9) }}</th>
            <th>{{ func_get_summ(10) }}</th>
            <th
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              {{ func_get_summ(11) }}
            </th>
            <th
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              {{ func_get_summ(12) }}
            </th>
            <th
              v-if="
                Number(
                  this.class_value.class.toString().replace(/[^0-9]/g, ''),
                ) <= 4
              "
            >
              {{ func_get_summ(13) }}
            </th>
          </tr>
        </tbody>
      </table>
      <button class="order__food_btn" @click="func_save_order">
        {{ new_order ? 'Сохранить' : 'Сохранить изменения' }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      school_list: [],
      class_list: [],
      school_value: null,
      class_value: null,
      date: '',
      login_teacher: '',
      order_food: [],
      progress: true,
      new_order: true,
      time: '',
      dostup: false,
      send: false,
      last_orders: [],
      selected: {
        bp: false,
        bpO: false,
        pp: false,
        ppO: false,
        ppP: false,
        lgZ1: false,
        lgO1: false,
        lgZ: false,
        lgO: false,
        gpd: false,
        gpdP: false,
        gpdPD: false,
      },
    };
  },
  methods: {
    func_refresh_student() {
      if (confirm('Вы действительно хотите обновить список учеников?')) {
        window
          .sendRequestAuth('/refresh_students', {
            school_id: this.school_value.school_id,
            class_id: this.class_value.class_id,
            date: this.date,
          })
          .then(() => {
            this.func_get_data();
          });
      }
    },
    func_check_pp(b, item) {
      if (b) {
        return false;
      } else {
        if (!this.dostup || Number(item.balance) < 60) {
          return true;
        } else {
          return false;
        }
      }
    },
    func_check_gpd(b, item) {
      if (b) {
        return false;
      } else {
        if (
          !this.dostup ||
          (Number(item.balance || 0) < 40 &&
            item.city.toLowerCase() == 'балаково') ||
          (Number(item.balance || 0) < 60 &&
            item.city.toLowerCase() == 'энгельс')
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    func_check_gpdP(b, item) {
      if (b) {
        return false;
      } else {
        if (!this.dostup || item.LGOTA == 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    func_check_gpdPD(b, item) {
      if (b) {
        return false;
      } else {
        if (
          !this.dostup ||
          item.LGOTA != 1 ||
          (Number(item.balance || 0) < 40 &&
            item.city.toLowerCase() == 'балаково') ||
          (Number(item.balance || 0) < 60 &&
            item.city.toLowerCase() == 'энгельс')
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    func_check_lg(b, item, summ) {
      if (b) {
        return false;
      } else {
        if (summ) {
          if (!this.dostup || item.LGOTA !== '1' || Number(item.balance) < 17) {
            return true;
          } else {
            return false;
          }
        } else {
          if (!this.dostup || item.LGOTA !== '1') {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    func_show_order(row) {
      this.school_value = {
        school_id: row.school_id,
        school: row.school,
      };
      this.class_value = {
        class: row.class,
        class_id: row.class_id,
      };
      this.date = row.dateOrder;
      this.func_get_data();
    },
    func_get_last_orders() {
      window.sendRequestAuth('/get_last_orders', {}).then(result => {
        if (!result) return;
        this.last_orders = result;
      });
    },
    func_update_lg_food(row, column) {
      if (!row || !column) return;
      let check = row[column];
      if (row.type_lgota && row.type_lgota == 'овз') {
        if (check) {
          console.log(row);
          if (column == 'lgZ') {
            row.lgZ1 = false;
          } else if (column == 'lgO') {
            row.lgO1 = false;
          } else if (column == 'lgZ1') {
            row.lgZ = false;
            if (row.lgO1 && row.lgP1) {
              row.lgP1 = false;
            }
          } else if (column == 'lgP1') {
            if (row.lgO1 && row.lgZ1) {
              row.lgZ1 = false;
            }
          } else if (column == 'lgO1') {
            if (row.lgP1 && row.lgZ1) {
              row.lgZ1 = false;
            }
          } else {
            row.lgO = false;
          }

          if (column == 'gpd') {
            row.gpdP = false;
            row.gpdPD = false;
          } else if (column == 'gpdP') {
            row.gpd = false;
            row.gpdPD = false;
          } else if (column == 'gpdPD') {
            row.gpd = false;
            row.gpdP = false;
          }
        }
      } else {
        if (check) {
          switch (column) {
            case 'lg':
              row.lgZ = false;
              row.lgO = false;
              row.lgZ1 = false;
              row.lgO1 = false;
              row.gpdP = false;
              row.gpdPD = false;
              break;
            case 'lgZ':
              row.lg = false;
              row.lgO = false;
              row.lgZ1 = false;
              row.lgO1 = false;
              row.gpdP = false;
              row.gpdPD = false;
              break;
            case 'lgO':
              row.lgZ = false;
              row.lg = false;
              row.lgZ1 = false;
              row.lgO1 = false;
              row.gpdP = false;
              row.gpdPD = false;
              break;
            case 'lgZ1':
              row.lgZ = false;
              row.lg = false;
              row.lgO = false;
              row.lgO1 = false;
              row.gpdP = false;
              row.gpdPD = false;
              break;
            case 'lgO1':
              row.lgZ = false;
              row.lg = false;
              row.lgZ1 = false;
              row.lgO = false;
              row.gpdP = false;
              row.gpdPD = false;
              break;
            case 'lgP1':
              row.lgZ = false;
              row.lg = false;
              row.lgZ1 = false;
              row.lgO = false;
              row.gpdP = false;
              row.gpdPD = false;
              break;

            case 'gpdP':
              row.lgZ = false;
              row.lg = false;
              row.lgZ1 = false;
              row.lgO = false;
              row.lgO1 = false;
              row.gpd = false;
              row.gpdPD = false;
              break;

            case 'gpdPD':
              row.lgZ = false;
              row.lg = false;
              row.lgZ1 = false;
              row.lgO = false;
              row.lgO1 = false;
              row.gpd = false;
              row.gpdP = false;
              break;

            case 'gpd':
              row.gpdP = false;
              row.gpdPD = false;
              break;
          }
        }
      }
    },
    func_update_bp_food(row, column) {
      if (!row || !column) return;
      let check = row[column];

      if (check) {
        switch (column) {
          case 'bp':
            row.bpO = false;
            break;
          case 'bpO':
            row.bp = false;
            break;
        }
      }
    },
    func_close_order() {
      this.order_food = [];
      this.selected = {
        bp: false,
        bpO: false,
        pp: false,
        ppO: false,
        ppP: false,
        lgZ1: false,
        lgO1: false,
        lgP1: false,
        lg_zav: false,
        lg_ob: false,
      };
    },
    func_clear_order() {
      this.selected = {
        bp: false,
        bpO: false,
        pp: false,
        ppO: false,
        ppP: false,
        lgZ1: false,
        lgO1: false,
        lgP1: false,
        lg_zav: false,
        lg_ob: false,
      };
      this.order_food.forEach(function (item) {
        item.bp = false;
        item.bpO = false;
        item.pp = false;
        item.ppO = false;
        item.ppP = false;
        item.lgZ1 = false;
        item.lgO1 = false;
        item.lgP1 = false;
        item.lgZ = false;
        item.lgO = false;
        item.gpd = false;
        item.gpdP = false;
        item.gpdPD = false;
      });
    },
    func_save_order() {
      if (this.send) return;
      if (this.order_food.length === 0) {
        window.soundClick();
        this.$notify({
          group: 'foo',
          title: 'Ошибка',
          text: 'Нет данных',
          type: 'error',
        });
        return;
      }
      if (!this.dostup) {
        window.soundClick();
        this.$notify({
          group: 'foo',
          title: 'Ошибка',
          text: 'Нет доступа',
          type: 'error',
        });
        return;
      }
      this.send = true;
      window
        .sendRequestAuth('/save_order_food', {
          data: JSON.stringify(this.order_food),
          new_order: this.new_order,
          date: this.date,
          login_teacher: this.login_teacher,
          school_id: this.school_value.school_id,
          class_id: this.class_value.class_id,
        })
        .then(res => {
          window.soundClick();
          this.$notify({
            group: 'foo',
            title: 'Информация',
            text: 'Выполнено успешно',
            type: 'info',
          });
          this.func_close_order();
          this.send = false;

          if (res) this.last_orders.unshift(res);
        });
    },
    func_get_school_list() {
      window.sendRequestAuth('/get_school_list_order', {}).then(result => {
        if (!result) return;
        this.school_list = result;
      });
    },
    func_get_class_list(item) {
      if (!item.school_id) return;
      window
        .sendRequestAuth('/get_closed_school', {
          school_id: item.school_id,
        })
        .then(result => {
          if (!result) {
            this.school_value = item;
            window
              .sendRequestAuth('/get_class_list_order', {
                school_id: this.school_value.school_id,
              })
              .then(result => {
                if (!result) return;
                this.class_list = result;
              });
          } else {
            this.class_list = [];
            this.$notify({
              group: 'foo',
              title: 'Ошибка',
              text: 'Выбранной школе запрещено делать заказ',
              type: 'error',
            });
          }
        });
    },
    func_get_data() {
      if (this.school_value && this.class_value && this.date) {
        const countDay = Math.ceil(
          (new Date(this.date) - new Date()) / (60 * 60 * 24 * 1000),
        );
        if (countDay > 10) {
          this.$notify({
            group: 'foo',
            title: 'Ошибка',
            text: 'Дата заказа не может быть больше текущей чем на 10 дней',
            type: 'error',
          });
          return;
        }
        window
          .sendRequestAuth('/get_order_food', {
            school_id: this.school_value.school_id,
            class_id: this.class_value.class_id,
            date: this.date,
          })
          .then(result => {
            if (!result) return;
            result.data.forEach(function (item) {
              item.bp = item.bp === 'true' ? true : false;
              item.bpO = item.bpO === 'true' ? true : false;
              item.pp = item.pp === 'true' ? true : false;
              item.ppO = item.ppO === 'true' ? true : false;
              item.ppP = item.ppP === 'true' ? true : false;
              item.lgZ1 = item.lgZ1 === 'true' ? true : false;
              item.lgO1 = item.lgO1 === 'true' ? true : false;
              item.lgP1 = item.lgP1 === 'true' ? true : false;
              item.lgZ = item.lgZ === 'true' ? true : false;
              item.lgO = item.lgO === 'true' ? true : false;
              item.gpd = item.gpd === 'true' ? true : false;
              item.gpdP = item.gpdP === 'true' ? true : false;
              item.gpdPD = item.gpdPD === 'true' ? true : false;
            });
            this.order_food = result.data;
            this.new_order = result.new;
            this.time = result.time;
            this.func_dostup();
          });
      } else {
        window.soundClick();
        this.$notify({
          group: 'foo',
          title: 'Ошибка',
          text: 'Заполните поля',
          type: 'error',
        });
      }
    },
    func_get_color(obj) {
      if (!obj) return;
      let balance = Number(obj.balance);
      let color = '';
      if (balance >= 200) {
        color = 'green';
      } else if (balance < 200 && balance >= 60) {
        color = 'orange';
      } else {
        color = 'red';
      }
      return color;
    },
    func_dostup() {
      let time_now = new Date();
      let time_teacher = new Date();
      let str_time = this.time.split(':');
      time_teacher = new Date(time_teacher.setHours(str_time[0]));
      time_teacher.setMinutes(str_time[1]);
      time_teacher.setSeconds(str_time[2]);

      let count_day = this.func_get_count_day_date(time_now, this.date);

      if (count_day < 0) {
        this.dostup = false;
      } else if (count_day === 0) {
        if (new Date(time_teacher) < time_now) {
          this.dostup = false;
        } else {
          this.dostup = true;
        }
      } else {
        this.dostup = true;
      }
    },

    func_get_count_day_date(start, end) {
      let date1 = new Date(start);
      let date2 = new Date(end);
      let oneDay = 1000 * 60 * 60 * 24;
      let diffInTime = date2.getTime() - date1.getTime();
      return Math.floor(diffInTime / oneDay) + 1;
    },
    func_get_summ(num) {
      let result = 0;

      this.order_food.forEach(function (item) {
        if (num === 1 && item.bp) result++;
        if (num === 2 && item.bpO) result++;
        if (num === 3 && item.pp) result++;
        if (num === 4 && item.ppO) result++;
        if (num === 5 && item.ppP) result++;
        if (num === 6 && item.lgZ1) result++;
        if (num === 7 && item.lgO1) result++;
        if (num === 8 && item.lgP1) result++;
        if (num === 9 && item.lgZ) result++;
        if (num === 10 && item.lgO) result++;
        if (num === 11 && item.gpd) result++;
        if (num === 12 && item.gpdP) result++;
        if (num === 13 && item.gpdPD) result++;
      });
      return result;
    },
  },
  mounted() {
    this.func_get_school_list();
    this.func_get_last_orders();
  },
  computed: {
    ...mapGetters(['USER_STATE']),
    select_all_bp: {
      get() {
        return this.selected.bp;
      },
      set(value) {
        this.selected.bp = value;
        this.order_food.forEach(function (item) {
          if (Number(item.class.replace(/[^0-9]/g, '')) <= 4) {
            item.bp = value;
            if (value) item.bpO = false;
          }
        });
        if (value) this.selected.bpO = false;
      },
    },
    select_all_bpO: {
      get() {
        return this.selected.bpO;
      },
      set(value) {
        this.selected.bpO = value;
        this.order_food.forEach(function (item) {
          if (Number(item.class.replace(/[^0-9]/g, '')) <= 4) {
            item.bpO = value;
            if (value) item.bp = false;
          }
        });
        if (value) this.selected.bp = false;
      },
    },
    select_all_pp: {
      get() {
        return this.selected.pp;
      },
      set(value) {
        this.selected.pp = value;
        this.order_food.forEach(function (item) {
          if (item.balance < 60) {
            item.pp = false;
          } else {
            item.pp = value;
          }
        });
      },
    },
    select_all_ppO: {
      get() {
        return this.selected.ppO;
      },
      set(value) {
        this.selected.ppO = value;
        this.order_food.forEach(function (item) {
          if (item.balance < 60) {
            item.ppO = false;
          } else {
            item.ppO = value;
          }
        });
      },
    },
    select_all_ppP: {
      get() {
        return this.selected.ppP;
      },
      set(value) {
        this.selected.ppP = value;
        this.order_food.forEach(function (item) {
          if (item.balance < 60) {
            item.ppP = false;
          } else {
            item.ppP = value;
          }
        });
      },
    },
    select_all_lg_food: {
      get() {
        return this.selected.lg_food;
      },
      set(value) {
        this.selected.lg = value;
        this.order_food.forEach(function (item) {
          if (!!Number(item.LGOTA) === false) {
            item.lg = false;
          } else {
            item.lg = value;
          }
        });
      },
    },
    select_all_lgZ1_food: {
      get() {
        return this.selected.lgZ1;
      },
      set(value) {
        this.selected.lgZ1 = value;
        if (value) {
          this.select_all_lg_zav = false;
          if (this.selected.lgO1 && this.selected.lgP1) {
            this.select_all_lgP1_food = false;
            this.selected.lgP1 = false;
          }
          this.order_food.forEach(item => {
            if (this.selected.lgO1) {
              if (!!Number(item.LGOTA) === false || item.type_lgota != 'овз') {
                item.lgZ1 = false;
              } else {
                item.lgZ1 = value;
                item.lgZ = false;
              }
            } else {
              if (!!Number(item.LGOTA) === false) {
                item.lgZ1 = false;
              } else {
                item.lgZ1 = value;
                item.lgZ = false;
              }
            }
          });
        } else {
          this.order_food.forEach(function (item) {
            if (!!Number(item.LGOTA) === false) {
              item.lgZ1 = false;
            } else {
              item.lgZ1 = value;
            }
          });
        }
      },
    },
    select_all_lgO1_food: {
      get() {
        return this.selected.lgO1;
      },
      set(value) {
        this.selected.lgO1 = value;
        if (value) {
          this.select_all_lg_ob = false;
          if (this.selected.lgZ1 && this.selected.lgP1) {
            this.select_all_lgP1_food = false;
            this.selected.lgP1 = false;
          }
          this.order_food.forEach(item => {
            if (this.selected.lgZ1) {
              if (!!Number(item.LGOTA) === false || item.type_lgota != 'овз') {
                item.lgO1 = false;
              } else {
                item.lgO1 = value;
                item.lgO = false;
              }
            } else {
              if (!!Number(item.LGOTA) === false) {
                item.lgO1 = false;
              } else {
                item.lgO1 = value;
                item.lgO = false;
              }
            }
          });
        } else {
          this.order_food.forEach(function (item) {
            if (!!Number(item.LGOTA) === false) {
              item.lgO1 = false;
            } else {
              item.lgO1 = value;
            }
          });
        }
      },
    },
    select_all_lgP1_food: {
      get() {
        return this.selected.lgP1;
      },
      set(value) {
        this.selected.lgP1 = value;

        if (value) {
          if (this.selected.lgZ1 && this.selected.lgO1) {
            this.select_all_lgZ1_food = false;
            this.selected.lgZ1 = false;
          }
          this.select_all_lg_ob = false;
          this.order_food.forEach(function (item) {
            if (!!Number(item.LGOTA) === false || item.type_lgota !== 'овз') {
              item.lgP1 = false;
            } else {
              item.lgP1 = value;
              item.lgO = false;
            }
          });
        } else {
          this.order_food.forEach(function (item) {
            if (!!Number(item.LGOTA) === false || item.type_lgota !== 'овз') {
              item.lgP1 = false;
            } else {
              item.lgP1 = value;
            }
          });
        }
      },
    },
    select_all_lg_zav: {
      get() {
        return this.selected.lgZ;
      },
      set(value) {
        this.selected.lgZ = value;
        if (value) {
          this.select_all_lgZ1_food = false;
          this.select_all_lg_ob = false;
          this.order_food.forEach(function (item) {
            if (
              !!Number(item.LGOTA) === false ||
              (!!Number(item.LGOTA) == true && Number(item.balance) < 17)
            ) {
              item.lgZ = false;
            } else {
              item.lgZ = value;
              item.lgZ1 = false;
            }
          });
        } else {
          this.order_food.forEach(function (item) {
            if (
              !!Number(item.LGOTA) === false ||
              (!!Number(item.LGOTA) == true && Number(item.balance) < 17)
            ) {
              item.lgZ = false;
            } else {
              item.lgZ = value;
            }
          });
        }
      },
    },
    select_all_lg_ob: {
      get() {
        return this.selected.lgO;
      },
      set(value) {
        this.selected.lgO = value;
        if (value) {
          this.select_all_lg_zav = false;
          this.select_all_lgO1_food = false;
          this.order_food.forEach(function (item) {
            if (
              !!Number(item.LGOTA) === false ||
              (!!Number(item.LGOTA) == true && Number(item.balance) < 17)
            ) {
              item.lgO = false;
            } else {
              item.lgO = value;
              item.lgO1 = false;
            }
          });
        } else {
          this.order_food.forEach(function (item) {
            if (
              !!Number(item.LGOTA) === false ||
              (!!Number(item.LGOTA) == true && Number(item.balance) < 17)
            ) {
              item.lgO = false;
            } else {
              item.lgO = value;
            }
          });
        }
      },
    },
    select_all_lg_gpd: {
      get() {
        return this.selected.gpd;
      },
      set(value) {
        if (value) {
          this.select_all_lg_gpdP = false;
          this.select_all_lg_gpdPD = false;
        }

        this.selected.gpd = value;
        this.order_food.forEach(function (item) {
          if (
            (Number(item.balance || 0) < 40 &&
              item.city.toLowerCase() == 'балаково') ||
            (Number(item.balance || 0) < 60 &&
              item.city.toLowerCase() == 'энгельс')
          ) {
            item.gpd = false;
          } else {
            item.gpd = value;
          }
        });
      },
    },
    select_all_lg_gpdP: {
      get() {
        return this.selected.gpdP;
      },
      set(value) {
        this.selected.gpdP = value;
        if (value) {
          this.select_all_lg_gpd = false;
          this.select_all_lg_gpdPD = false;
        }
        this.order_food.forEach(function (item) {
          if (Number(item.LGOTA || 0) != 1) {
            item.gpdP = false;
          } else {
            item.gpdP = value;
          }
        });
      },
    },
    select_all_lg_gpdPD: {
      get() {
        return this.selected.gpdPD;
      },
      set(value) {
        this.selected.gpdPD = value;
        if (value) {
          this.select_all_lg_gpdP = false;
          this.select_all_lg_gpd = false;
        }
        this.order_food.forEach(function (item) {
          if (
            Number(item.LGOTA || 0) != 1 ||
            (Number(item.balance || 0) < 40 &&
              item.city.toLowerCase() == 'балаково') ||
            (Number(item.balance || 0) < 60 &&
              item.city.toLowerCase() == 'энгельс')
          ) {
            item.gpdPD = false;
          } else {
            item.gpdPD = value;
          }
        });
      },
    },
  },
};
</script>

<style>
.btn__refresh_student {
  margin-bottom: 20px;
  background: #f9d45c;
  color: #fff;
  text-decoration: none;
  text-align: center;
  padding: 10px 15px;
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  transition: 0.5s;
  display: block;
}
</style>
